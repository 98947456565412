import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { ThemeProvider } from "@mui/material/styles"
import { theme } from "./theme"
import Layout from "Layout"
import ResetPassword from "ResetPassword"
import Home from "Home"

const App = () => {
	return (
		<ThemeProvider theme={theme}>
			<Layout>
				<Router>
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/reset" element={<ResetPassword />} />
					</Routes>
				</Router>
			</Layout>
		</ThemeProvider>
	)
}

export default App
