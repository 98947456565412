type Env = {
	apiUrl: string
}

const env = () => {
	const vars = {
		apiUrl: process.env.REACT_APP_API_URL,
	}

	if (process.env.NODE_ENV === "production") {
		vars.apiUrl = (window as any).REACT_APP_API_URL
	}

	return vars as Env
}

export const getUrl = (route: string): string => {
	return `${env().apiUrl}${route}`
}
