import React from "react";
import { Box, Container, Icon } from "@mui/material";

const Layout = ({ children }: { children: React.ReactNode }) => (
	<Container
		// maxWidth="sm"
		sx={{
			marginY: 9,
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		}}
	>
		{children}
		<Box
			sx={{
				display: "flex",
				width: "70%",
				justifyContent: "center",
				marginTop: 6,
				padding: 2,
				borderTop: "1px solid",
				borderColor: "divider",
				boxSizing: "border-box",
			}}
		>
			<img alt="Teamsplate" src={'logo-text-teamsplate.png'} width="100%"/>
		</Box>
	</Container>
);

export default Layout;
