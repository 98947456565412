import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import { getUrl } from "utils/api";

const ResetPassword = () => {
	const [searchParams] = useSearchParams();
	const token = searchParams.get("token");
	const email = searchParams.get("email");
	const expirationDate = searchParams.get("expirationDate");

	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [isResetButtonDisabled, setIsResetButtonDisabled] = useState(true);
	const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
	const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
		useState("");
	const [resetPasswordErrorMessage, setResetPasswordErrorMessage] =
		useState("");
	const [resetPasswordSuccessMessage, setResetPasswordSuccessMessage] =
		useState("");

	useEffect(() => {
		const isValid = isFormValid();
		setIsResetButtonDisabled(!isValid);
	}, [password, confirmPassword]);

	function isFormValid(): boolean {
		let isValid = true;

		setPasswordErrorMessage("");
		setConfirmPasswordErrorMessage("");

		if (password !== "" && password.length < 8) {
			isValid = false;
			setPasswordErrorMessage(
				"Votre mot de passe doit contenir au moins 8 caractères."
			);
		}
		if (
			password !== "" &&
			confirmPassword !== "" &&
			password !== confirmPassword
		) {
			isValid = false;
			setConfirmPasswordErrorMessage("Les mots de passe ne correspondent pas.");
		}

		if (password === "" || confirmPassword === "") {
			isValid = false;
		}

		return isValid;
	}

	const onResetButtonAction = async () => {
		console.log("onResetButtonAction expirationDate", expirationDate);

		const parameters = JSON.stringify({
			password: password,
			email: email,
			expirationDate: expirationDate,
			token: token,
		});

		const response = await fetch(getUrl("/users/resetPassword"), {
			headers: new Headers({
				"Content-Type": "application/json",
			}),
			body: parameters,
			method: "POST",
		});

		if (response.status === 200 || response.status === 201) {
			setResetPasswordSuccessMessage("Votre mot de passe a été modifié.");
		} else {
			const data = await response.json();
			console.log("onResetButtonAction data", data);
			if (data.statusCode === 400) {
				if (data.message === "Link expired") {
					setResetPasswordErrorMessage(
						"Le lien a expiré. Veuillez demander un nouveau lien via l'application mobile."
					);
				} else if (data.message === "User not found") {
					setResetPasswordErrorMessage(
						"Identifiant incorrect. Veuillez demander un nouveau lien via l'application mobile."
					);
				} else if (data.message === "Token not valid") {
					setResetPasswordErrorMessage(
						"Lien incorrect. Veuillez demander un nouveau lien via l'application mobile."
					);
				}
			}
		}
	};

	return (
		<>
			<TextField
				fullWidth
				sx={{
					marginTop: 4,
				}}
				id="password"
				label="Nouveau mot de passe"
				variant="standard"
				onChange={(event) => {
					const { value } = event.target;
					setPassword(value);
				}}
			/>
			{passwordErrorMessage.length > 0 ? (
				<div
					style={{ color: "red", fontFamily: "sans-serif", marginTop: "10px" }}
				>
					{passwordErrorMessage}
				</div>
			) : null}
			<TextField
				fullWidth
				sx={{
					marginTop: 4,
				}}
				id="confirm-password"
				label="Confirmation du mot de passe"
				variant="standard"
				onChange={(event) => {
					const { value } = event.target;
					setConfirmPassword(value);
				}}
			/>
			{confirmPasswordErrorMessage.length > 0 ? (
				<div
					style={{ color: "red", fontFamily: "sans-serif", marginTop: "10px" }}
				>
					{confirmPasswordErrorMessage}
				</div>
			) : null}
			<div style={{ marginTop: "10px" }}>
				<Button
					variant="contained"
					onClick={onResetButtonAction}
					disabled={isResetButtonDisabled}
				>
					Réinitialiser
				</Button>
			</div>
			{resetPasswordErrorMessage.length > 0 ? (
				<div
					style={{ color: "red", fontFamily: "sans-serif", marginTop: "10px" }}
				>
					{resetPasswordErrorMessage}
				</div>
			) : null}
			{resetPasswordSuccessMessage.length > 0 ? (
				<div
					style={{
						color: "green",
						fontFamily: "sans-serif",
						marginTop: "10px",
					}}
				>
					{resetPasswordSuccessMessage}
				</div>
			) : null}
		</>
	);
};

export default ResetPassword;
