import React from "react";

const Home = () => {
	return (
		<>
			Home
		</>
	);
};

export default Home;
